import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";
import logo from '../img/avaworld.png';
import axios from 'axios';

const Login = () => {
  const [err, setErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErr(false);
    
    const phone = e.target[0].value;
    const password = e.target[1].value;

    const phoneRegex = /^1[3-9]\d{9}$/;

    if (!phoneRegex.test(phone)) {
      setErr(true);
      setLoading(false);
      return;
    }

    try {
      const formData = new FormData();
      formData.append("username", phone);
      formData.append("password", password);

      const response = await axios.post('https://avaworld.com.cn/api/login', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.data && response.data.access_token) {
        // 先存储token
        localStorage.setItem('access_token', response.data.access_token);
        
        // 等待一小段时间确保token已经存储
        await new Promise(resolve => setTimeout(resolve, 100));
        
        // 确保token已经存储后再跳转
        setLoading(false);
        setErr(false);
        
        window.location.href = '/browse';
      } else {
        throw new Error('Login failed: No access token received');
      }
    } catch (err) {
      console.error('Login error:', err);
      setErr(true);
      setLoading(false);
    }
  };

  return (
    <div className="formContainer">
      <div className="formWrapper">
        <div style={{ position: 'relative' }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <span className="logo" style={{ position: 'relative' }}>
              <img className="log" src={logo} alt=""/>
              <span style={{
                position: 'absolute',
                right: '-50px',
                top: '5px',
                padding: '4px 8px',
                backgroundColor: '#f0f0f0',
                border: '1px solid #ddd',
                borderRadius: '4px',
                fontSize: '12px',
                color: '#666',
                fontWeight: '500'
              }}>公测1.0</span>
            </span>
          </div>
        </div>
        <span className="title"></span>
        <form onSubmit={handleSubmit}>
          <input type="tel" placeholder="请输入手机号" disabled={loading} />
          <input type="password" placeholder="请输入密码" disabled={loading} />
          <button disabled={loading}>{loading ? "登录中..." : "登录"}</button>
          {err && <span className="er">* 手机号或者密码有误</span>}
        </form>
        <p>没有账号？ <Link to="/register" className="link">请注册</Link></p>
      </div>
    </div>
  );
};

export default Login;