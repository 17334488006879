import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../context/AxiosContext';

const Browse = () => {
  const [activeTab, setActiveTab] = useState('全部');
  const [avaList, setAvaList] = useState([]);
  const navigate = useNavigate();

  const tabs = ['全部', '恋与深空', '原神', '二次元', '国创'];

  useEffect(() => {
    const fetchFilteredAva = async () => {
      try {
        const response = await axiosInstance.get('/filter_ava', {
          params: { tag: activeTab },
        });
        console.log('Filtered Ava:', response.data);
        setAvaList(response.data.results);
      } catch (error) {
        console.error('Failed to fetch filtered Ava:', error);
      }
    };

    fetchFilteredAva();
  }, [activeTab]);

  return (
    <div className="browse-container">
      {/* Top Navigation */}
      <div className="browse-nav">
        <div className="tabs-container">
          {tabs.map((tab) => (
            <button
              key={tab}
              className={`tab ${activeTab === tab ? 'active' : ''}`}
              onClick={() => {
                setAvaList([]); // Clear the ava-card list
                setActiveTab(tab); // Set the active tab
              }}
            >
              {tab}
            </button>
          ))}
        </div>
        <button className="search-button">
          <i className="fas fa-search"></i>
        </button>
      </div>

      {/* Ava Cards */}
      {avaList.map((ava) => (
        <div key={ava.ava_id} className="ava-card">
          <div className="ava-image" style={{ backgroundImage: `url(${ava.ava_preview})` }}>
            <h2 className="ava-name">{ava.ava_name}</h2>
            <div className="ava-subtitle">{ava.ava_subtitle}</div> 
          </div>
          <div className="ava-info">
            <div className="ava-description">
              {ava.ava_descr}
            </div>
            <div className="ava-intro">
              <h3>简介</h3>
              <p>{ava.ava_intro}</p>
            </div>
          </div>
          <button className="align-image-button" onClick={async () => {
            try {
              const response = await axiosInstance.get('/add_ava', {
                params: { ava_id: ava.ava_id },
              });
              localStorage.setItem('chat_meta', JSON.stringify(response.data.result));
              navigate("/chatroom");
              console.log('Add Ava Response:', response.data);
            } catch (error) {
              console.error('Failed to add Ava:', error);
            }
          }}>打招呼</button>
        </div>
      ))}

      {/* Bottom Navigation */}
      <div className="bottom-nav">
        <button className="nav-button" onClick={() => navigate('/chat')}>对话</button>
        <button className="nav-button active">浏览</button>
        <button className="nav-button" onClick={() => navigate('/my')}>我的</button>
      </div>
    </div>
  );
};

export default Browse;
