import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../context/AxiosContext';

const Circum = () => {
  const navigate = useNavigate();
  const [circumMeta, setCircumMeta] = useState({});
  const [inputCircum, setInputCircum] = useState("");
  const chatMeta = JSON.parse(localStorage.getItem('chat_meta'));

  const handleGenerateCircum = async () => {
    try {
      const response = await axiosInstance.get('/generate_circum');
      console.log(response.data);
      if (response.data && response.data.circum_meta) {
        setCircumMeta(response.data.circum_meta);
        setInputCircum(response.data.circum_meta.system_content);
      }
    } catch (error) {
      console.error('Failed to generate circum:', error);
    }
  };

  const handleSubmit = async () => {
    try {
      // Get the latest circum data

      // Store the entire result object in localStorage
      if (circumMeta) {
        const response = await axiosInstance.post('/save_circum', {
          relationship_id: chatMeta.relationship_id,
          system_content: inputCircum,
          defaultcircum_id: circumMeta.defaultcircum_id
        });
        if (response.data && response.data.circum_meta) {
          localStorage.setItem('circum_meta', JSON.stringify(response.data.circum_meta));
        }
        // Navigate to circumroom
        navigate("/circumroom");
      }
    } catch (error) {
      console.error('Error during submission:', error);
    }
  };

  return (
    <div className="circum-page">
      <div className="circum-header">
        <button className="back-btn" onClick={() => navigate(-1)}>
          <i className="fas fa-arrow-left"></i>
        </button>
        <h1>情况脚本</h1>
        <div className="right-placeholder"></div>
      </div>

      <div className="tab-container">
        <button 
          className="login-style-btn" 
          onClick={handleGenerateCircum}
        >
          AI辅助生成
        </button>
      </div>

      <div className="script-container">
        <textarea
          className="script-input"
          placeholder="请输入你想和角色一起享受的情形"
          value={inputCircum}
          onChange={(e) => setInputCircum(e.target.value)}
        />
        <div className="example-text">
          例子：第二天穿着校服去游乐园。鬼屋发生突发情况
        </div>
      </div>

      <button 
        className="submit-btn"
        onClick={handleSubmit}
        style={{
          background: 'var(--gradient-primary)',
          border: 'none',
          color: 'var(--text-primary)',
          padding: '12px 24px',
          fontWeight: '600',
          borderRadius: '12px',
          cursor: 'pointer',
          fontSize: '16px',
          transition: 'all 0.3s ease'
        }}
      >
        用这种情形进行对话
      </button>
    </div>
  );
};
export default Circum;
