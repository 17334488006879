import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axiosInstance from '../context/AxiosContext';
import { useAuth } from '../context/AuthContext';

const Chat = () => {
  const { currentUser } = useAuth();
  const [chatList, setChatList] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchChatList = async () => {
      try {
        const response = await axiosInstance.get('/get_chat_list');
        const { chat_list } = response.data;
        setChatList(chat_list);
      } catch (error) {
        console.error('Failed to fetch chat list:', error);
      }
    };

    fetchChatList();
  }, []);

  const handleChatClick = (chat) => {
    // Store chat information in localStorage
    localStorage.setItem('chat_meta', JSON.stringify(chat));
    navigate('/chatroom');
  };

  return (
    <div className="chat-container">
      {/* Top Navigation */}
      <div className="browse-nav" style={{ display: 'flex', justifyContent: 'center' }}>
        <div className="title">聊天列表</div>
        <button className="search-button">
          <i className="fas fa-search"></i>
        </button>
      </div>

      {/* Chat List */}
      <div className="chat-list">
        {chatList.map((chat) => (
          <div
            key={chat.relationship_id}
            className="chat-item"
            onClick={() => handleChatClick(chat)}
          >
            <div className="chat-content">
              <div className="avatar">
                <img src={chat.ava_avatar} alt={chat.ava_name} />
                {chat.is_online && <div className="online-indicator" />}
              </div>
              <div className="chat-info">
                <div className="chat-name">{chat.ava_name}</div>
                <div className="last-message">{chat.last_message}</div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Bottom Navigation */}
      <div className="bottom-nav">
        <button 
          className={`nav-button ${location.pathname === '/chat' ? 'active' : ''}`}
          onClick={() => navigate('/chat')}
        >
          对话
        </button>
        <button 
          className={`nav-button ${location.pathname === '/browse' ? 'active' : ''}`}
          onClick={() => navigate('/browse')}
        >
          浏览
        </button>
        <button 
          className={`nav-button ${location.pathname === '/my' ? 'active' : ''}`}
          onClick={() => navigate('/my')}
        >
          我的
        </button>
      </div>
    </div>
  );
};

export default Chat;
