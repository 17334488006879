import Login from "./pages/Login";
import Register from "./pages/Register";
import Browse from "./pages/Browse";
import Chat from "./pages/Chat";
import ChatRoom from "./pages/ChatRoom";
import Circum from "./pages/Circum";
import CircumRoom from './pages/CircumRoom';
import Event from "./pages/Event";
import My from "./pages/My";
import "./style.scss";
import { BrowserRouter, Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useContext } from "react";
import { useAuth } from "./context/AuthContext";
import ChatWindow from "./components/ChatWindow"
import PlusPage from "./components/PlusPage"
import { AnimatePresence, motion } from 'framer-motion';

function App() {
  const variants = {
    hidden: { x: '100%', opacity: 0 },
    visible: { x: 0, opacity: 1 },
  };
  const transition = {
    duration: 0.4,
    ease: 'easeInOut',
  };

  const { currentUser } = useAuth();
  console.log(currentUser);
  // const location = useLocation();

  const ProtectedRoute = ({ children }) => {
    if (!currentUser) {
      return <Navigate to="/login" />;
    }

    return children
  };

  const RootRoute = () => {
    if (currentUser) {
      return <Navigate to="/browse" />;
    }
    return <Navigate to="/login" />;
  };

  return (
    <BrowserRouter>
      <AnimatePresence mode="wait">
        <Routes>
          <Route path="/">
            <Route index element={<RootRoute />} />
            <Route path="login" element={<Login/>}/>
            <Route path="register" element={<Register/>}/>
            <Route path="browse" element={
              <motion.div
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={variants}
                transition={transition}
                className="page"
              >
                <ProtectedRoute><Browse /></ProtectedRoute>
              </motion.div>
            }/>
            <Route path="chat" element={
              <motion.div
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={variants}
                transition={transition}
                className="page"
              >
                <ProtectedRoute><Chat /></ProtectedRoute>
              </motion.div>
            }/>
            <Route path="chatroom" element={
              <motion.div
                initial="initial"
                animate="animate"
                exit="exit"
                variants={{
                  initial: { opacity: 1 },
                  animate: { opacity: 1 },
                  exit: { opacity: 1 }
                }}
                transition={{ duration: 0.4 }}
                className="page"
              >
                <ProtectedRoute><ChatRoom /></ProtectedRoute>
              </motion.div>
            } />
            <Route path="circumroom" element={
              <motion.div
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={variants}
                transition={transition}
                className="page"
              >
                <ProtectedRoute><CircumRoom /></ProtectedRoute>
              </motion.div>
            } />
            <Route path="plus/" element={
              // <motion.div
              //     initial="hidden"
              //     animate="visible"
              //     exit="hidden"
              //     variants={variants}
              //     transition={transition}
              //     // className="page"
              // >
                <PlusPage />
              // </motion.div>
            } />
            <Route path="circum" element={
              <motion.div
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={variants}
                transition={transition}
                className="page"
              >
                <ProtectedRoute><Circum /></ProtectedRoute>
              </motion.div>
            } />
            <Route path="event" element={
              <motion.div
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={variants}
                transition={transition}
                className="page"
              >
                <ProtectedRoute><Event /></ProtectedRoute>
              </motion.div>
            } />
            <Route path="my" element={
              <motion.div
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={variants}
                transition={transition}
                className="page"
              >
                <ProtectedRoute><My /></ProtectedRoute>
              </motion.div>
            } />
          </Route>
        </Routes>
      </AnimatePresence>
    </BrowserRouter>
  );
}

export default App;
