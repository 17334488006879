import React, { useState } from "react";
import Add from "../img/addAvatar.png";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import logo from '../img/avaworld.png';

const Register = () => {
    const [err, setErr] = useState(false);
    const [registerErr, setRegisterErr] = useState(false);
    //   const [loading, setLoading] = useState(false);
      const navigate = useNavigate();
    const [resMessage, setResMessage] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const phone = e.target[0].value;
        const password = e.target[1].value;
        const confirmPassword = e.target[2].value;

        const phoneRegex = /^1[3-9]\d{9}$/;

        if (!phoneRegex.test(phone)) {
            setErr(true);
            return;
        }
        if (password !== confirmPassword) {
            setErr(true);
            return;
        }
        setErr(false);

        try {
            const response = await axios.post('http://47.119.191.51:9990/api/register', {
                phone,
                password
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.data && response.data.message === "success") {
                setResMessage("注册成功，即将跳转到登录页面");
                setRegisterErr(false);
                setTimeout(() => {
                    navigate("/login");
                }, 2000);
            } else {
                setResMessage(response.data?.message || "注册失败，请稍后重试");
                setRegisterErr(true);
            }
        } catch (error) {
            console.error("Registration error:", error);
            setResMessage(error.response?.data?.message || "注册失败，请稍后重试");
            setRegisterErr(true);
        }
    };

    return (
        <div className="formContainer">
            <div className="formWrapper">
                <span className="logo"><img className="log" src={logo} alt=""/></span>
                <span className="title"></span>
                <form onSubmit={handleSubmit}>
                    {/*<input type="text" placeholder="Enter Your First Name"/>*/}
                    <input type="tel" placeholder="输入手机号"/>
                    <input type="password" placeholder="输入密码"/>
                    <input type="password" placeholder="确认密码"/>
                    <button>注册</button>
                    {/* disable loading */}
                    {/* {loading && "Uploading and compressing the image please wait..."} */}
                    {err && <span className="er">* 手机号格式有误或密码不一致</span>}
                    {registerErr && <span className="er">{resMessage}</span>}
                    {registerErr || <span className="info">{resMessage}</span>}
                </form>
                <p>
                    已经有账号？ <Link to="/login" className="link">请登录</Link>
                </p>
            </div>
        </div>
    );
};

export default Register;