import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const My = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({
    user_name: '',
    avatar_url: '/default-avatar.jpg',
    phone: ''
  });

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const token = localStorage.getItem('access_token');
        const response = await axios.get('http://47.119.191.51:9990/api/get_user', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        
        if (response.data.message === 'success' && response.data.result) {
          setUserInfo({
            user_name: response.data.result.user_name || '探索者',
            avatar_url: response.data.result.avatar_url || '/default-avatar.jpg',
            phone: response.data.result.phone || ''
          });
        }
      } catch (error) {
        console.error('Failed to fetch user info:', error);
      }
    };

    fetchUserInfo();
  }, []);

  return (
    <div className="my-container">
      {/* Header */}
      <div className="my-header">
        <h1>关于我</h1>
        <button className="feedback-btn">意见反馈</button>
      </div>

      {/* Profile Section */}
      <div className="profile-section">
        <div className="avatar-container">
          <img src={userInfo.avatar_url} alt="用户头像" className="avatar" />
        </div>
        <div className="user-info">
          <h2>{userInfo.user_name}</h2>
          <button className="edit-profile-btn">
            <i className="fas fa-pencil"></i>
            编辑资料
          </button>
        </div>
        <button 
          className="logout-btn"
          onClick={() => {
            localStorage.clear();
            document.cookie.split(";").forEach((c) => {
              document.cookie = c
                .replace(/^ +/, "")
                .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
            });
            navigate("/login");
          }}
        >
          退出登录
        </button>
      </div>

      {/* VIP Section */}
      <div className="vip-section">
        <div className="vip-info">
          <span className="vip-tag">VIP会员</span>
          <span className="vip-duration">剩余: 30天</span>
        </div>
        <button className="vip-btn">点击续费 →</button>
      </div>

      {/* Wallet Section */}
      <div className="wallet-section">
        <div className="wallet-header">
          <i className="fas fa-wallet"></i>
          钱包
        </div>
        <div className="diamond-balance">
          <i className="fas fa-gem"></i>
          <span>30</span>
        </div>
        <p className="wallet-description">
          每轮对话消耗2颗钻石，首次注册送50颗钻石，未订阅用户每日可有30颗钻石额度
        </p>
      </div>

      {/* Bottom Navigation */}
      <div className="bottom-nav">
        <button className="nav-button" onClick={() => navigate('/chat')}>对话</button>
        <button className="nav-button" onClick={() => navigate('/browse')}>浏览</button>
        <button className="nav-button active">我的</button>
      </div>
    </div>
  );
};

export default My;
