import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const Event = () => {
  const navigate = useNavigate();

  return (
    <div className="event-page">
      <div className="event-header">
        <button className="back-btn" onClick={() => navigate(-1)}>
          <i className="fas fa-arrow-left"></i>
        </button>
        <h1>事件</h1>
        <div className="right-placeholder"></div>
      </div>

      <div className="event-content">
        <div className="event-section">
          <div className="section-header">
            <h2>与我的事件</h2>
            <div className="help-icon">
              <i className="fas fa-question-circle"></i>
            </div>
          </div>
          
          <div className="section-description">
            这是一个管理我与角色之间的经验的空间，可以影响角色的
            思想、情感
            和行为的变化。可以添加可能对角色产生重大影响的与我的事
            件。
          </div>

          <div className="event-list">
            <div className="event-item">
              <span className="event-text">
                大三 暑假，打工的星巴克
              </span>
              <span className="event-text">
                和来喝咖啡的他发生争执，从此以后两个人结下
              </span>
            </div>
          </div>
        </div>

        <button className="add-event-btn">
          <i className="fas fa-plus"></i>
          添加事件(0/10)
        </button>
      </div>
    </div>
  );
};

export default Event;
