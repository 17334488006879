import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import axiosInstance from '../context/AxiosContext';
import { useAuth } from '../context/AuthContext';
import VoiceMessage from '../components/VoiceMessage';
import leftArrow from '../img/leftarrow.png';
import addIcon from '../img/add.png';
import sendIcon from '../img/send.png';
import audioIcon from '../img/audio.png';
import playIcon from '../img/play.png';
import loadingIcon from '../img/loading.png';
import selectOkIcon from '../img/selectok.png';
import downloadIcon from '../img/download.png';
import axios from 'axios';

const ChatRoom = () => {
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);
  const [longPressedMessageId, setLongPressedMessageId] = useState(null);
  const longPressTimeoutRef = useRef(null);
  const messagesEndRef = useRef(null);
  const [socketWsChat, setSocketWsChat] = useState(null);
  const [socketWsChatBackground, setSocketWsChatBackground] = useState(null);
  const [title, setTitle] = useState('');
  const [backgroundVideo, setBackgroundVideo] = useState('');
  const [currentAudio, setCurrentAudio] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [loadingAudioId, setLoadingAudioId] = useState(null);
  const [isSelectMode, setIsSelectMode] = useState(false);
  const [selectedMessageIds, setSelectedMessageIds] = useState([]);
  const [theaterButtonState, setTheaterButtonState] = useState('initial');
  const chatMeta = JSON.parse(localStorage.getItem('chat_meta'));
  const token = localStorage.getItem('access_token');
  const [theaterVideoUrl, setTheaterVideoUrl] = useState('');

  // 添加统一的勾选框样式
  const checkboxStyle = {
    minWidth: '18px',
    minHeight: '18px',
    width: '18px',
    height: '18px',
    cursor: 'pointer',
    margin: '8px',
    marginRight: '12px',
    flexShrink: 0,
    flexGrow: 0,
    padding: 0,
    boxSizing: 'border-box'
  };

  const checkboxContainerStyle = message => ({
    display: 'flex',
    alignItems: 'flex-start',
    height: '100%',
    marginLeft: message.role === 'user' ? '12px' : 0,
    marginTop: message.role !== 'user' ? '14px' : '16px',
  });

  // 初始化聊天 WebSocket
  const initWsChat = () => {
    const socket_ws_chat = new WebSocket(`ws://47.119.191.51:9990/api/ws_chat`);

    socket_ws_chat.onopen = () => {
      console.log('ws_chat open');
      socket_ws_chat.send(JSON.stringify({ token: token }));
    };

    socket_ws_chat.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log('Received message data structure:', data);

      if ("status" in data) {
        socket_ws_chat.send(JSON.stringify({ "status": "received" }));
        setTitle(data.status);  
      } else {
        // Ensure the message has the required fields
        const messageData = {
          ...data,
          content_type: data.content_type || 'text',
          text: data.text || '',
          audio_url: data.audio_url || '',
        };
        setMessages((prevMessages) => [...prevMessages, messageData]);
        setTitle(chatMeta.ava_name);  
      }
    };

    socket_ws_chat.onerror = (error) => {
      console.error('ws_chat error:', error);
    };

    socket_ws_chat.onclose = () => {
      console.log("ws_chat closed");
    };

    setSocketWsChat(socket_ws_chat);
  };

  // 初始化背景 WebSocket
  const initWsChatBackground = () => {
    const socket_ws_chat_background = new WebSocket(`ws://47.119.191.51:9990/api/ws_chat_background`);
    
    socket_ws_chat_background.onopen = () => {
      console.log('ws_chat_background open');
      socket_ws_chat_background.send(JSON.stringify({ token: token }));
    };

    socket_ws_chat_background.onmessage = (event) => {
      // const data = JSON.parse(event.data);
      // console.log(data);
      // if ("background_url" in data) {
      //   setBackgroundVideo(data.background_url);
      //   socket_ws_chat_background.send("{}");
      // } else {
      //   // "info" in data
      //   const jsonMessage = JSON.stringify({ chat_meta: chatMeta });
      //   socket_ws_chat_background.send(jsonMessage);
      // }
    };

    socket_ws_chat_background.onerror = (error) => {
      console.error('ws_chat_background error:', error);
    };

    socket_ws_chat_background.onclose = () => {
      console.log("ws_chat_background closed");
    };

    setSocketWsChatBackground(socket_ws_chat_background);
  };

  // 处理音频播放
  const handleAudioPlay = (audioUrl) => {
    if (currentAudio) {
      currentAudio.pause();
      if (currentAudio.src === audioUrl && isPlaying) {
        setIsPlaying(false);
        return;
      }
    }

    const audio = new Audio(audioUrl);
    audio.play();
    setCurrentAudio(audio);
    setIsPlaying(true);

    audio.onended = () => {
      setIsPlaying(false);
      setCurrentAudio(null);
    };
  };

  // 处理获取音频并播放
  const handleTextToAudio = async (message) => {
    setLoadingAudioId(message.record_id);
    try {
      if (message.audio_url==="" || message.audio_url===null){
        const response = await axiosInstance.get('/get_chat_audio', {
          params: { ava_id: chatMeta.ava_id, record_id: message.record_id },
        });
        if (response.data.message === 'success' && response.data.result) {
          message.audio_url = response.data.result;
        }
      }
      handleAudioPlay(message.audio_url);
    } catch (error) {
      console.error('获取音频失败:', error);
    } finally {
      setLoadingAudioId(null);
    }
  };

  const toggleMessageSelection = (messageId) => {
    if (!isSelectMode) return;

    setSelectedMessageIds(prevSelected => {
      const isCurrentlySelected = prevSelected.includes(messageId);
      const newSelectedMessageIds = isCurrentlySelected
        ? prevSelected.filter(id => id !== messageId)
        : [...prevSelected, messageId];
      
      // Update theater button state based on selection
      setTheaterButtonState(newSelectedMessageIds.length > 0 ? 'selected' : 'initial');
      
      return newSelectedMessageIds;
    });
  };

  const createTheater = async () => {
    try {
      if (!selectedMessageIds) {
        setTheaterButtonState('initial');
      }
      else {
        setTheaterButtonState('loading');

        const response = await axiosInstance.post('/generate_theater', {
          chat_meta: chatMeta,
          messages: selectedMessageIds
            .sort((a, b) => a - b) // 先对 ID 进行排序
            .map(id => {
              const message = messages.find(msg => msg.record_id === id);
              return message;
            })
        });
        if (! response.data) {
          setTheaterButtonState('initial');
        }
        else {
          setTheaterVideoUrl(response.data.result);
        }
      }

      // 重置选择状态
      setIsSelectMode(false);
      setSelectedMessageIds([]);
    } catch (error) {
      console.error('Failed to generate theater:', error);
      // Reset to initial state on error
      setTheaterButtonState('initial');
      setIsSelectMode(false);
      setSelectedMessageIds([]);
    }
  };

  const toggleExpanded = () => {
    if (isExpanded) {
      // 如果是收起菜单，同时重置勾选状态
      setIsSelectMode(false);
      setSelectedMessageIds([]);
    }
    setIsExpanded(!isExpanded);
  };

  // 在组件挂载时初始化 WebSocket 连接
  useEffect(() => {
    initWsChat();
    initWsChatBackground();

    // 组件卸载时清理连接
    return () => {
      if (socketWsChat) {
        socketWsChat.close();
      }
      if (socketWsChatBackground) {
        socketWsChatBackground.close();
      }
    };
  }, []);

  useEffect(() => {
    const fetchChatHistory = async () => {
      try {
        const response = await axiosInstance.get(`/chat_message_list`, {
          params: { ava_id: chatMeta.ava_id },
        });
        setMessages(response.data.results || []);
        console.log(response.data.results);
      } catch (error) {
        console.error('Failed to fetch chat history:', error);
      }
    };

    fetchChatHistory();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const checkTheaterVideoUrl = async () => {
    try {
      const response = await fetch(theaterVideoUrl, { method: 'HEAD' });
      console.log('Response status:', response.status);
      
      if (response.ok) {  // status >= 200 && status < 300
        setTheaterButtonState('downloadable');
      } else {
        // setTheaterButtonState('loading');
      }
    } catch (error) {
      // 对于阿里云 OSS 的 mp4 文件，即使有 CORS 错误也认为是可下载的
      // if (theaterVideoUrl.includes('oss-cn-shenzhen.aliyuncs.com') && 
          // theaterVideoUrl.endsWith('.mp4')) {
        // setTheaterButtonState('downloadable');
      // } else {
        // setTheaterButtonState('loading');
      // }
      console.log('Checking video URL:', error);
    }
  };

  useEffect(() => {
    let intervalId;
    
    if (theaterButtonState === 'loading') {
      intervalId = setInterval(async () => {
        try {
          await checkTheaterVideoUrl();
        } catch (error) {
          console.log('Checking theater video URL:', error);
        }
      }, 5000); // Check every 30 seconds
    }

    // Cleanup interval on unmount or when state changes
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [theaterButtonState, theaterVideoUrl]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!inputMessage.trim()) return;

    if (socketWsChat && socketWsChat.readyState === WebSocket.OPEN) {
      console.log(inputMessage);
      const jsonMessage = JSON.stringify({ chat_meta: chatMeta, text: inputMessage });
      const data = { content_type: "text", text: inputMessage, audio_url: "", role: "user" };

      // 先更新本地消息状态
      setMessages(prevMessages => [...prevMessages, data]);

      try {
        socketWsChat.send(jsonMessage);
        console.log(jsonMessage);
        setInputMessage(''); // 清空输入框
      } catch (error) {
        console.error('Error sending message:', error);
        // 如果发送失败，可以选择回滚本地消息状态
        setMessages(prevMessages => prevMessages.slice(0, -1));
      }
    } else {
      initWsChat();
      //多请求一次
      if (socketWsChat && socketWsChat.readyState === WebSocket.OPEN) {
        console.log(inputMessage);
        const jsonMessage = JSON.stringify({ chat_meta: chatMeta, text: inputMessage });
        const data = { content_type: "text", text: inputMessage, audio_url: "", role: "user" };

        // 先更新本地消息状态
        setMessages(prevMessages => [...prevMessages, data]);

        try {
          socketWsChat.send(jsonMessage);
          console.log(jsonMessage);
          setInputMessage(''); // 清空输入框
        } catch (error) {
          console.error('Error sending message:', error);
          // 如果发送失败，可以选择回滚本地消息状态
          setMessages(prevMessages => prevMessages.slice(0, -1));
        }
      } else {
        console.log("WebSocket is not connected");
      }
    }
  };

  const downloadVideo = () => {
    try {
      // 直接在新窗口打开下载链接
      const link = document.createElement('a');
      link.href = theaterVideoUrl;
      link.target = '_blank'; // 在新窗口打开
      link.rel = 'noopener noreferrer'; // 安全设置
      
      // 从 URL 中提取文件名，如果没有则使用默认名称
      const fileName = theaterVideoUrl.split('/').pop() || 'video.mp4';
      link.download = fileName;
      
      // 触发点击
      document.body.appendChild(link);
      link.click();
      
      // 清理
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error initiating video download:', error);
    }
    setTheaterButtonState('initial');
  };

  // 添加资源清理的 effect
  useEffect(() => {
    // 清理函数
    return () => {
      // 停止正在播放的音频
      if (currentAudio) {
        currentAudio.pause();
        currentAudio.src = '';
        setCurrentAudio(null);
      }
      
      // 重置播放状态
      setIsPlaying(false);
      setLoadingAudioId(null);
      
      // 清理 WebSocket 连接
      if (socketWsChat) {
        socketWsChat.close();
        setSocketWsChat(null);
      }
      if (socketWsChatBackground) {
        socketWsChatBackground.close();
        setSocketWsChatBackground(null);
      }
      
      // 清理其他状态
      setTheaterButtonState('initial');
      setTheaterVideoUrl('');
      setIsSelectMode(false);
      setSelectedMessageIds([]);
      setMessages([]);
      setInputMessage('');
    };
  }, [currentAudio, socketWsChat, socketWsChatBackground]);

  return (
    <div className="chatroom-container">
      <div>
        <div className="background-container">
          <div className="background-image-wrapper">
            <div className="background-image-container">
              <video
                  autoPlay
                  poster="noposter"
                  loop
                  muted
                  playsInline
                  src={backgroundVideo || chatMeta.ava_bg_pic}
                  className="background-image"
              />
            </div>
          </div>
          <div className="background-overlay" />
        </div>

        {/* Header */}
        <div className="chatroom-header">
          <button className="back-button" onClick={() => navigate("/chat")}>
            <img src={leftArrow} alt="back" className="back-icon" />
          </button>
          <div className="ava-info" style={{display: 'flex', justifyContent: 'center'}}>
            <div className="ava-name">{title || chatMeta.ava_name}</div>
          </div>
        </div>

        {/* Messages Area */}
        <div className="messages-container"
             style={{
               overflow: 'auto',
               height: 'calc(60vh - 60px)',
               left: 0,
               right: 0,
               top: '40vh',
               position: 'fixed',
               display: 'flex',
               flexDirection: 'column'
             }}>
          {messages.map((message, index) => (
            <div
              key={index}
              className={`message ${message.role === 'user' ? 'user-message' : 'ava-message'}`}
              style={{ 
                marginBottom: '8px',
                position: 'relative',
                width: '100%'
              }}
            >
              <div style={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: message.role === 'user' ? 'flex-end' : 'flex-start',
                width: '100%',
                position: 'relative'
              }}>
                {isSelectMode && message.role !== 'user' && (
                  <div style={checkboxContainerStyle(message)}>
                    <input
                      type="checkbox"
                      checked={selectedMessageIds.includes(message.record_id)}
                      onChange={() => toggleMessageSelection(message.record_id)}
                      style={{...checkboxStyle, marginLeft: 0, marginTop: 0}}
                    />
                  </div>
                )}
                {message.role !== 'user' && (
                  <img
                    src={chatMeta.ava_avatar}
                    alt="Avatar"
                    className="message-avatar"
                    style={{
                      alignSelf: 'flex-start',
                      marginTop: '4px'  
                    }}
                  />
                )}
                <div className="message-content">
                  {message.role !== 'user' && message.content_type === 'audio' ? (
                    <VoiceMessage message={message} />
                  ) : (
                    <div className="text-message">
                      <p style={{ margin: 0 }}>{message.text}</p>
                      {message.role !== 'user' && (
                        <div className="play-button">
                          <img 
                            src={loadingAudioId === message.record_id ? loadingIcon : playIcon}
                            alt={loadingAudioId === message.record_id ? "加载中" : "播放"}
                            style={{ 
                              height: '16px', 
                              width: 'auto', 
                              cursor: 'pointer',
                              animation: loadingAudioId === message.record_id ? 'spin 1s linear infinite' : 'none'
                            }}
                            onClick={() => handleTextToAudio(message)}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {isSelectMode && message.role === 'user' && (
                  <div style={checkboxContainerStyle(message)}>
                    <input
                      type="checkbox"
                      checked={selectedMessageIds.includes(message.record_id)}
                      onChange={() => toggleMessageSelection(message.record_id)}
                      style={{...checkboxStyle, marginTop: 0}}
                    />
                  </div>
                )}
              </div>
            </div>
          ))}
          <div ref={messagesEndRef} style={{ height: 0 }} />
        </div>

        {/* Input Area */}
        <motion.div 
          className="input-area"
          animate={{ height: isExpanded ? "140px" : "80px" }} 
          transition={{ 
            type: "spring",
            stiffness: 500,
            damping: 40,
            mass: 1
          }}
        >
          <div className="input-row" style={{ 
            display: 'flex',
            alignItems: 'center', 
            height: '100%' 
          }}>
            <motion.button 
              className="plus-button"
              onClick={toggleExpanded}
              animate={{ rotate: isExpanded ? -45 : 0 }}
              transition={{ 
                type: "spring",
                stiffness: 500,
                damping: 30
              }}
            >
              <img 
                src={addIcon} 
                alt="add" 
                className="add-icon" 
                style={{ 
                  width: '100%', 
                  height: '100%', 
                  background: 'linear-gradient(90deg, #7633ab 0%, #e41919 50%, #e99c2f 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  filter: 'none'
                }} 
              />
            </motion.button>
            <form className="input-container" onSubmit={handleSendMessage}>
              <input
                type="text"
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                placeholder="发送消息..."
                className="message-input"
              />
              <button type="submit" className="send-button">
                <img src={sendIcon} alt="send" className="send-icon" style={{ width: '100%', height: '100%' }} />
              </button>
            </form>
          </div>
          <AnimatePresence>
            {isExpanded && (
              <motion.div 
                className="action-buttons"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 10 }}
                transition={{ 
                  type: "spring",
                  stiffness: 500,
                  damping: 30,
                  mass: 0.8
                }}
              >
                <motion.button 
                  className="action-btn"
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 5 }}
                  transition={{ duration: 0.2 }}
                  onClick={() => navigate('/circum')}
                >
                  <span>情况</span>
                </motion.button>
                <motion.button 
                  className="action-btn"
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 5 }}
                  transition={{ duration: 0.2 }}
                >
                  <span>事件</span>
                </motion.button>
                <motion.button 
                  className="action-btn"
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 5 }}
                  transition={{ duration: 0.2 }}
                  onClick={() => {
                    switch(theaterButtonState) {
                      case 'initial':
                        if (!isSelectMode) {
                          setIsSelectMode(true);
                          setSelectedMessageIds([]);
                        }
                        break;
                      case 'selected':
                        createTheater();
                        break;
                      case 'loading':
                        // Optionally handle loading state
                        break;
                      case 'downloadable':
                        downloadVideo();
                        break;
                    }
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '5px'
                  }}
                >
                  {theaterButtonState === 'loading' ? (
                    <img 
                      src={loadingIcon} 
                      alt="loading" 
                      style={{ 
                        width: '20px', 
                        height: '20px',
                        animation: 'spin 1s linear infinite',
                        transform: 'rotate(0deg)',
                        transformOrigin: 'center'
                      }} 
                    />
                  ) : theaterButtonState === 'downloadable' ? (
                    <img src={downloadIcon} alt="download" style={{ width: '20px', height: '20px' }} />
                  ) : theaterButtonState === 'selected' ? (
                    <img src={selectOkIcon} alt="selected" style={{ width: '20px', height: '20px' }} />
                  ) : (
                    <span>剧场</span>
                  )}
                </motion.button>
                <motion.button 
                  className="action-btn"
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 5 }}
                  transition={{ duration: 0.2 }}
                >
                  <span>秘密之地</span>
                </motion.button>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      </div>
    </div>
  );
};

export default ChatRoom;
