import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import axiosInstance from '../context/AxiosContext';
import { useAuth } from '../context/AuthContext';
import leftArrow from '../img/leftarrow.png';
import addIcon from '../img/add.png';
import sendIcon from '../img/send.png';
import downIcon from '../img/down.png';
import musicIcon from '../img/music.png';
import loadingIcon from "../img/loading.png";
import playIcon from "../img/play.png";

const CircumRoom = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isExpanded, setIsExpanded] = useState(true);
  const [hasCollapsedOnce, setHasCollapsedOnce] = useState(false);
  const [showOverlayVideo, setShowOverlayVideo] = useState(false);
  const messagesEndRef = useRef(null);
  const [socketWsCircum, setSocketWsCircum] = useState(null);
  const wsInitialized = useRef(false);  
  const [title, setTitle] = useState('');
  const chatMeta = JSON.parse(localStorage.getItem('chat_meta'));
  const circumMeta = JSON.parse(localStorage.getItem('circum_meta'));
  const token = localStorage.getItem('access_token');
  const audioRef = useRef(null);
  const [isMusicPlaying, setIsMusicPlaying] = useState(false);
  const [loadingAudioId, setLoadingAudioId] = useState(null);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);

  // 初始化聊天 WebSocket
  const initWsCircum = () => {
    // 如果已经有连接，先关闭它
    if (socketWsCircum) {
      socketWsCircum.close();
    }

    const socket_ws_circum = new WebSocket(`ws://47.119.191.51:9990/api/ws_circum`);

    socket_ws_circum.onopen = () => {
      console.log('ws_circum open');
      const combinedMeta = { ...chatMeta, ...circumMeta };
      const jsonMessage = JSON.stringify({ token: token, circum_meta: combinedMeta });
      socket_ws_circum.send(jsonMessage);
    };

    socket_ws_circum.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log('Received message:', data);

      if ("status" in data) {
        setTitle("");  //data.status
      } else {
        setMessages((prevMessages) => [...prevMessages, data]);

        //收到自动播放
        if (data.message === 'success' && data.audio_url) {
          handleAudioPlay(data.audio_url);
        }
        setTitle("");  //chatMeta.ava_name
      }
    };

    socket_ws_circum.onerror = (error) => {
      console.error('ws_circum error:', error);
    };

    socket_ws_circum.onclose = () => {
      console.log("ws_circum closed");
    };

    setSocketWsCircum(socket_ws_circum);
  };

  // 在组件挂载时初始化 WebSocket 连接
  useEffect(() => {
    if (!wsInitialized.current) {  
      wsInitialized.current = true;
      initWsCircum();
    }

    // 组件卸载时清理连接
    return () => {
      if (socketWsCircum) {
        socketWsCircum.close();
        wsInitialized.current = false;
      }
    };
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);


  // 组件卸载时清理音频
  useEffect(() => {
    const newAudio = new Audio(circumMeta.bg_music);
    newAudio.pause();
    audioRef.current = newAudio;
    setIsMusicPlaying(false);

    playOrPause();

    return () => {
      if (audioRef.current) {
        playOrPause(); // 暂停播放
        audioRef.current.src = ''; // 清除src
        audioRef.current.load(); // 重新加载空的音频，释放资源
        audioRef.current = null; // 清除引用
      }
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const screenHeight = window.innerHeight;
      if (initialHeight > screenHeight) {
        // 键盘弹出，调整页面高度
        document.body.style.paddingBottom = `${keyboardHeight}px`;
      } else {
        // 键盘收起，恢复原始页面高度
        document.body.style.paddingBottom = '0px';
      }
    };

    window.addEventListener('resize', handleResize);
    const initialHeight = window.innerHeight;
    let keyboardHeight = 0;

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const playOrPause = async () => {
    if (isMusicPlaying) {
      await audioRef.current.pause();
      setIsMusicPlaying(false);
    }
    else {
      await audioRef.current.play();
      setIsMusicPlaying(true);
      console.log("播放")
    }
  };

  // 音乐播放控制
  const toggleMusic = async () => {
    console.log("点击音乐按钮")
    if (!audioRef.current) {
      console.log('No audio element found');
      return;
    }

    try {
      console.log('Before toggle:', {
        paused: audioRef.current.paused,
        currentTime: audioRef.current.currentTime,
        readyState: audioRef.current.readyState,
        networkState: audioRef.current.networkState,
        src: audioRef.current.src
      });

      playOrPause();

      console.log('After toggle:', {
        paused: audioRef.current.paused,
        currentTime: audioRef.current.currentTime,
        readyState: audioRef.current.readyState,
        networkState: audioRef.current.networkState
      });
    } catch (error) {
      console.error('Error in toggleMusic:', error);
    }
  };


  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };


  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!inputMessage.trim()) return;

    if (socketWsCircum && socketWsCircum.readyState === WebSocket.OPEN) {
      console.log(inputMessage);
      const data = { content_type: "text", text: inputMessage, audio_url: "", role: "user" };
      const jsonMessage = JSON.stringify({text: inputMessage });
      // 先更新本地消息状态
      setMessages(prevMessages => [...prevMessages, data]);

      try {
        socketWsCircum.send(jsonMessage);
        console.log(jsonMessage);
        setInputMessage(''); // 清空输入框
      } catch (error) {
        console.error('Error sending message:', error);
        // 如果发送失败，可以选择回滚本地消息状态
        setMessages(prevMessages => prevMessages.slice(0, -1));
      }
    } else {
      // initWsCircum();
      // //多请求一次
      // if (socketWsCircum && socketWsCircum.readyState === WebSocket.OPEN) {
      //   console.log(inputMessage);
      //   const jsonMessage = JSON.stringify({ chat_meta: chatMeta, text: inputMessage });
      //   const data = { content_type: "text", text: inputMessage, audio_url: "", role: "user" };
      //
      //   // 先更新本地消息状态
      //   setMessages(prevMessages => [...prevMessages, data]);
      //
      //   try {
      //     socketWsCircum.send(jsonMessage);
      //     console.log(jsonMessage);
      //     setInputMessage(''); // 清空输入框
      //   } catch (error) {
      //     console.error('Error sending message:', error);
      //     // 如果发送失败，可以选择回滚本地消息状态
      //     setMessages(prevMessages => prevMessages.slice(0, -1));
      //   }
      // } else {
      //   console.log("WebSocket is not connected");
      // }
    }
  };

  // 处理语音音频播放
  const handleAudioPlay = (audioUrl) => {
    if (currentAudio) {
      currentAudio.pause();
      if (currentAudio.src === audioUrl && isPlaying) {
        setIsPlaying(false);
        return;
      }
    }

    const audio = new Audio(audioUrl);
    audio.play();
    setCurrentAudio(audio);
    setIsPlaying(true);

    audio.onended = () => {
      setIsPlaying(false);
      setCurrentAudio(null);
    };
  };

  // 处理获取音频并播放
  const handleTextToAudio = async (message) => {
    setLoadingAudioId(message.record_id);
    try {
      if (message.audio_url==="" || message.audio_url===null){
        const response = await axiosInstance.get('/get_circum_audio', {
          params: { ava_id: chatMeta.ava_id, record_id: message.record_id },
        });
        if (response.data.message === 'success' && response.data.result) {
          message.audio_url = response.data.result;
        }
      }
      handleAudioPlay(message.audio_url);
    } catch (error) {
      console.error('获取音频失败:', error);
    } finally {
      setLoadingAudioId(null);
    }
  };


  return (
      <div className="chatroom-container">
        <button
          onClick={toggleMusic}
          style={{
            position: 'fixed',
            top: '20px',
            right: '20px',
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            background: 'rgba(255, 255, 255, 0)',
            border: 'none',
            outline: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            zIndex: 100,
            padding: 0,
          }}
        >
          <motion.img
            src={musicIcon}
            alt="Toggle Music"
            animate={{
              opacity: isMusicPlaying ? 1 : 0.5,
            }}
            style={{
              width: '24px',
              height: '24px',
              filter: 'brightness(10)',
              userSelect: 'none'
            }}
          />
        </button>
        <div>
          <div className="background-container">
            <div className="background-image-wrapper">
              <div className="background-image-container">
                <video
                    autoPlay
                    poster="noposter"
                    loop
                    muted
                    playsInline
                    src={circumMeta.bg_pic}
                    className="background-image"
                    style={{
                      position: 'fixed',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100vh',
                      objectFit: 'cover',
                      zIndex: -2
                    }}
                />
                {showOverlayVideo && chatMeta?.ava_bg_seg && (
                  <motion.video
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 0.5 }}
                      autoPlay
                      poster="noposter"
                      loop
                      muted
                      playsInline
                      src={chatMeta.ava_bg_seg}
                      className="background-overlay-video"
                      style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100vh',
                        objectFit: 'cover',
                        zIndex: -1
                      }}
                  />
                )}
              </div>
            </div>
            <div className="background-overlay" />
            {/* Add frosted glass overlay for text display */}
            <motion.div 
              className="frosted-glass-overlay"
              animate={{
                height: isExpanded ? '50vh' : '0vh',
                padding: isExpanded ? '20px' : '0px'
              }}
              transition={{ duration: 0.3, ease: "easeInOut" }}
              onAnimationComplete={() => {
                if (!isExpanded && !hasCollapsedOnce) {
                  setHasCollapsedOnce(true);
                  setShowOverlayVideo(true);
                }
              }}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                background: 'rgba(255, 255, 255, 0.1)',
                backdropFilter: 'blur(10px)',
                WebkitBackdropFilter: 'blur(10px)',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                overflow: 'hidden',
                zIndex: 2
              }}
            >
              <motion.div 
                className="text-display"
                animate={{
                  opacity: isExpanded ? 1 : 0,
                  scale: isExpanded ? 1 : 0.98
                }}
                transition={{ duration: 0.2 }}
                style={{
                  background: 'rgba(255, 255, 255, 0.1)',
                  padding: '20px',
                  borderRadius: '12px',
                  color: 'white',
                  maxWidth: '800px',
                  margin: '0 auto',
                  width: '100%',
                  height: 'calc(50vh - 80px)',
                  overflowY: 'auto',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  border: '1px solid rgba(255, 255, 255, 0.2)',
                  position: 'relative',
                  userSelect: 'text',
                  WebkitUserSelect: 'text',
                  cursor: 'text'
                }}
              >
                <style>
                  {`
                    .text-display::-webkit-scrollbar {
                      width: 6px;
                    }
                    .text-display::-webkit-scrollbar-track {
                      background: rgba(255, 255, 255, 0.1);
                      border-radius: 3px;
                    }
                    .text-display::-webkit-scrollbar-thumb {
                      background: rgba(255, 255, 255, 0.3);
                      border-radius: 3px;
                    }
                    .text-display::-webkit-scrollbar-thumb:hover {
                      background: rgba(255, 255, 255, 0.4);
                    }
                  `}
                </style>
                <div style={{ 
                  padding: '10px 0',
                  userSelect: 'text',
                  WebkitUserSelect: 'text'
                }}>
                  <p style={{ 
                    fontSize: '0.9rem', 
                    lineHeight: '1.6', 
                    margin: 0,
                    whiteSpace: 'pre-wrap',
                    fontWeight: '400',
                    userSelect: 'text',
                    WebkitUserSelect: 'text'
                  }}>
                    {circumMeta.system_content || '加载中...'}
                  </p>
                </div>
              </motion.div>

            </motion.div>
          </div>

          {/* Header */}
          <div className="chatroom-header">
            <button className="back-button" onClick={() => navigate("/chat")}>
              <img src={leftArrow} alt="back" className="back-icon" />
            </button>
            <div className="ava-info" style={{display: 'flex', justifyContent: 'center'}}>
              <div className="ava-name">{title}</div>
            </div>
          </div>

          {/* Messages Area */}
          <div className="messages-container"
               style={{
                 overflow: 'auto',
                 height: 'calc(60vh - 60px)',
                 left: 0,
                 right: 0,
                 top: '40vh',
                 position: 'fixed',
                 display: 'flex',
                 flexDirection: 'column'
               }}>
            {messages.map((message, index) => (
                <div
                    key={index}
                    className={`message ${message.role === 'user' ? 'user-message' : 'ava-message'}`}
                    style={{ marginBottom: '8px' }}
                >
                  {message.role !== 'user' && (
                      <img
                          src={chatMeta.ava_avatar}
                          alt="Avatar"
                          className="message-avatar"
                      />
                  )}
                  <div className="message-content">
                    <div className="text-message">
                      <p style={{ margin: 0 }}>{message.text}</p>
                      {message.role !== 'user' && (
                          <div className="play-button">
                            <img
                                src={loadingAudioId === message.record_id ? loadingIcon : playIcon}
                                alt={loadingAudioId === message.record_id ? "加载中" : "播放"}
                                style={{
                                  height: '16px',
                                  width: 'auto',
                                  cursor: 'pointer',
                                  animation: loadingAudioId === message.record_id ? 'spin 1s linear infinite' : 'none'
                                }}
                                onClick={() => handleTextToAudio(message)}
                            />
                          </div>
                      )}
                    </div>
                  </div>
                </div>
            ))}
            <div ref={messagesEndRef} style={{ height: 0 }} />
          </div>

          {/* Input Area */}
          <motion.div
              className="input-area"
              animate={{ height: isExpanded ? "140px" : "80px" }}
              transition={{
                type: "spring",
                stiffness: 500,
                damping: 40,
                mass: 1
              }}
          >
            <div className="input-row" style={{
              display: 'flex',
              alignItems: 'center',
              height: '100%'
            }}>
              <motion.button
                  className="plus-button"
                  onClick={() => setIsExpanded(!isExpanded)}
                  animate={{ rotate: isExpanded ? -45 : 0 }}
                  transition={{
                    type: "spring",
                    stiffness: 500,
                    damping: 30
                  }}
              >
                <img
                    src={addIcon}
                    alt="add"
                    className="add-icon"
                    style={{
                      width: '100%',
                      height: '100%',
                      background: 'linear-gradient(90deg, #7633ab 0%, #e41919 50%, #e99c2f 100%)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                      filter: 'none'
                    }}
                />
              </motion.button>
              <form className="input-container" onSubmit={handleSendMessage}>
                <input
                    type="text"
                    value={inputMessage}
                    onChange={(e) => setInputMessage(e.target.value)}
                    placeholder="发送消息..."
                    className="message-input"
                />
                <button type="submit" className="send-button">
                  <img src={sendIcon} alt="send" className="send-icon" style={{ width: '100%', height: '100%' }} />
                </button>
              </form>
            </div>
            <AnimatePresence>
              {isExpanded && (
                  <motion.div
                      className="action-buttons"
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 10 }}
                      transition={{
                        type: "spring",
                        stiffness: 500,
                        damping: 30,
                        mass: 0.8
                      }}
                  >
                    <motion.button
                        className="action-btn"
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 5 }}
                        transition={{ duration: 0.2 }}
                        onClick={() => navigate('/chatroom')}
                    >
                      <span>聊天</span>
                    </motion.button>
                    <motion.button
                        className="action-btn"
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 5 }}
                        transition={{ duration: 0.2 }}
                    >
                      <span>事件</span>
                    </motion.button>
                    <motion.button
                        className="action-btn"
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 5 }}
                        transition={{ duration: 0.2 }}
                        style={{
                          backgroundColor: 'transparent',
                          cursor: 'default',
                          pointerEvents: 'none',
                          opacity: 0.5,
                          color: '#808080'
                        }}
                    >
                      <span>剧场</span>
                    </motion.button>
                    <motion.button
                        className="action-btn"
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 5 }}
                        transition={{ duration: 0.2 }}
                    >
                      <span>秘密之地</span>
                    </motion.button>
                  </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        </div>
      </div>
  );
};

export default CircumRoom;
