import React, { useState, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import audioIcon from '../img/audio.png';


const VoiceMessage = ({ message }) => {
    const [showText, setShowText] = useState(false);
    const [isLongPressed, setIsLongPressed] = useState(false);
    const audioRef = useRef(null);
    const longPressTimer = useRef(null);
    const longPressThreshold = 500; // 长按阈值（毫秒）
    const clickTimer = useRef(null); // 用于区分点击和长按

    const handlePlay = (event) => {
        event.preventDefault();
        event.stopPropagation();
        
        // 如果是长按结束，不要播放音频
        if (isLongPressed) {
            return;
        }
        
        // 如果点击时间小于长按阈值，播放音频
        if (audioRef.current) {
            audioRef.current.play();
        }
    };

    const handlePressStart = (event) => {
        event.preventDefault();
        event.stopPropagation();
        
        // 清除之前的计时器
        if (longPressTimer.current) {
            clearTimeout(longPressTimer.current);
        }
        
        // 设置点击开始时间
        clickTimer.current = Date.now();
        
        // 设置长按计时器
        longPressTimer.current = setTimeout(() => {
            setIsLongPressed(true);
            setShowText(prev => !prev);
        }, longPressThreshold);
    };

    const handlePressEnd = (event) => {
        event.preventDefault();
        event.stopPropagation();
        
        // 清除长按计时器
        if (longPressTimer.current) {
            clearTimeout(longPressTimer.current);
        }
        
        // 计算按压时间
        const pressDuration = Date.now() - (clickTimer.current || 0);
        
        // 如果按压时间小于阈值，且不是长按状态，则视为点击，播放音频
        if (pressDuration < longPressThreshold && !isLongPressed && audioRef.current) {
            audioRef.current.play();
        }
        
        setIsLongPressed(false);
        clickTimer.current = null;
    };

    const handleTouchMove = (event) => {
        if (longPressTimer.current) {
            clearTimeout(longPressTimer.current);
        }
        setIsLongPressed(false);
        clickTimer.current = null;
    };

    return (
        <motion.div 
            className="voiceMessage"
            layout
        >
            <motion.div 
                className="voiceContent"
                onMouseDown={handlePressStart}
                onMouseUp={handlePressEnd}
                onMouseLeave={handlePressEnd}
                onTouchStart={handlePressStart}
                onTouchEnd={handlePressEnd}
                onTouchMove={handleTouchMove}
                whileTap={{ scale: 0.95 }}
                style={{
                    padding: '4px 12px 4px 4px',
                    display: 'flex',
                    alignItems: 'center',
                    minHeight: '20px',
                    minWidth: '32px'
                }}
            >
                <img
                    src={audioIcon}
                    alt="Play audio"
                    className="audioIcon"
                    style={{
                        height: '14px',
                        width: 'auto',
                        display: 'block',
                        marginLeft: '-2px'
                    }}
                />
                <audio 
                    ref={audioRef} 
                    src={message.audio_url} 
                    style={{ display: 'none' }} 
                />
            </motion.div>
            <AnimatePresence>
                {showText && (
                    <motion.div 
                        className="messageText"
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: 'auto' }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.2 }}
                    >
                        {message.text}
                    </motion.div>
                )}
            </AnimatePresence>
        </motion.div>
    );
};

export default VoiceMessage;
